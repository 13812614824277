import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

function escapeHTML() {
  const code = '<iframe width="640" height="360" src="//www.teleport.io/api/v2/player?feedid=fevc3lxysgty&playmode=liveimageloop&playmodespossible=liveimageloop,livevideostream,historyimage,livevideoclip&playframeskipinterval=min&playframecount=60" frameborder="0" allow="fullscreen" allowfullscreen></iframe>'
  return code.replace(/&/g, '&amp;')
             .replace(/</g, '&lt;')
             .replace(/>/g, '&gt;')
             .replace(/"/g, '&quot;')
             .replace(/'/g, '&#039;');
}
//<a href='/deliverables' class='text-blue-600 hover:underline'>this document</a>.
const faqData = [
  {
    id: 1,
    question: "¿Cuales son los entregables?",
    answer: "En Lisual somos una empresa de software, los entregables los podes descargar vos, cuando quieras desde la página web que se creo, no tenemos incumbencia alguna si la cámara estuvo mal ubicada y el contenido salio defectuoso, no nos hacemos cargo de la edición de timelapse ya que la misma es automatica desde LisualPro"
    },
    {
    id: 2,
    question: "¿Quien abona el chip 4G/5G?",
    answer: "Es responsabilidad del cliente, comprar un chip, ponerselo al sistema y cerciorarse que tenga datos en todo momento. Recomendamos que contrates plan con ABONO, no PREPAGO."
    },
    {
    id: 3,
    question: "¿Como puedo ver la cámara en vivo en tiempo real desde mi PC?",
    answer: "Si la cámara es marca Dahua, con este programa: <a href='https://dahuawiki.com/SmartPSS' class='text-blue-600 hover:underline'>Click Aca!</a><br/> Si la cámara es marca TB-XXX, con este programa: <a href='https://drive.google.com/drive/folders/1lgEGf94YutA3oCV4AMuSZWabgHlUVfuS?usp=sharing' class='text-blue-600 hover:underline'>Click Aca!</a>"
    },
    {
    id: 4,
    question: "¿De quien es responsabilidad la tarea de instalación?",
    answer: "Del cliente, cualquier tipo de falla electrica o de datos que pueda repercutir en el funcionamiento. En caso de exisitir errores sobre la cámara, la misma será reemplazada por Lisual."
    },
    {
    id: 5,
    question: "¿Lisual realizá tareas de campo?",
    answer: "No, somos una empresa de software, sin incumbencia alguna en tareas de campo."
    },
    {
    id: 6,
    question: "¿Cuales son nuestros tiempos de respuesta?",
    answer: "Nuestro equipo de soporte responderá en un maximo tiempo de 24 horas."
    },
    {
    id: 7,
    question: "¿Qué hago si no puedo ver la cámara?",
    answer: "Verificá que le este llegando energía a la cámara con exito, luego verifica la conexión de internet, y el pago de los datos moviles en el chip, si el problema persiste comunicá los inconvenientes por el grupo de WhatsApp."
    },
    {
    id: 8,
    question: "¿Qué sucede luego de que realizo el pago?",
    answer: "Apenas se acredita el dinero, armaremos un grupo de WhatsApp en donde estará todo nuestro equipo técnico y tu equipo, en el mismo te solicitaremos la dirección y alli te enviaremos los sistemas entre 5 a 8 días hábiles."
    },
    {
    id: 9,
    question: "¿Necesitas comprobante?",
    answer: "Luego de recibir el pago nuestro equipo administrativo se pondrá en contacto contigo via WhatsApp para enviarte el recibo de pago."
    },
    {
    id: 10,
    question: "¿Como armo un timelapse en vertical para Instagram?",
    answer: "Para llevarlo a cabo segui este proceso: 1. Descargar el timelapse en la velocidad, resolución y si queres que sea del dia, de los últimos 30 días o de todos los tiempos, desde la función 'live time-lapse' desde el menu superior derecho del dashboard. 2. Hacé click en el botón ↓ y te va redireccionar a una web en donde en la esquina inferior izquierda encontrarás 3 puntos ( ⁝ ) y dale en 'DESCARGAR'. 3. Con el video en la galería de tu dispositivo dale en compartir y subilo directamente a tu Instagram. BONUS: Recordá agregarle alguna musica de las que propone Instagram como tendencia ❤"
    },
    {
    id: 11,
    question: "¿Como integrar LisualPro en mi web?",
    answer:`<p>Para llevarlo a cabo pasale este codigo a tu desarrollador:</br> ${escapeHTML()} </br>y pedile que cambie la parte del codigo marca en azul, por el feed id de tu cuenta que lo encontraras al cliquear sobre el boton rojo de tu dashboard en al URL</p>`
    }
  // Add more questions as needed
];

const FAQItem = ({ id, question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="flex justify-between items-center w-full py-4 px-6 text-left hover:bg-gray-50"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{id}. {question}</span>
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isOpen && (
        <div 
          className="px-6 py-4 bg-gray-50"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      )}
    </div>
  );
};

const FAQComponent = () => {
  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Preguntas Frecuentes</h2>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        {faqData.map((item) => (
          <FAQItem key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};

export default FAQComponent;