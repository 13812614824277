import React, { useState, useEffect, useCallback } from 'react';
import { Bell, Menu, Camera, Clock, Archive, Home, Users, Settings, CreditCard, HelpCircle, BookUser, Book, PresentationIcon, DoorOpen, CloudSunIcon } from 'lucide-react';
import Calendar from './Calendar.js';
import './App.css'
import logo from './media/lisualpro.png';
import descarga from './media/descarga.png'
import { collection, getDocs } from "firebase/firestore";
import { db } from './firebase-config';
import ImageGallery from './ImageGallery.js';
import { useLocation, useNavigate } from 'react-router-dom';
import UserProfileCard from './Profile.js';
import NotificationForm from './NotificationForm.js'
import Instagram from "./Instagram.js"
import Timelapse from './Timelapse.js';
import FAQTab from './faq.js';
import WeatherComponent from './weather.js';
import idea from './media/idea.png'

const ConstructionDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState('Inicio');
  const [objects, setObjects] = useState({})
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [userdata, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const [dayTime, setDayTime] = useState("")


  const livecam = `//www.teleport.io/api/v2/player?feedid=${userdata.feedID}&playmode=liveimageloop&imageplayspeed=1fps&playframeskipinterval=day&playframecount=600`
  const timelapse = `https://www.teleport.io/api/v2/video-get?feedid=${userdata.feedID}&videoquery=all-time&videotype=live&fileName=video.mp4`
  const location = useLocation();
  const navigate = useNavigate();
  const word = location.state?.keyword || 'No word provided';

  const fetchPost = async () => {
    await getDocs(collection(db, "usuarios"))
      .then((querySnapshot) => {
        const newData = querySnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }));
        setObjects(newData);
        newData.forEach(element => {
          if (element.identifier == word) {
            setUserData(element);
          }
        });
      })
  }

  useEffect(() => {
    fetchPost();
    var currentTime = new Date()
    
    if(currentTime.getHours() < 12){
      setDayTime("buen día  👋🏼")
    }
    else if(currentTime.getHours()>=12 && currentTime.getHours()<18){
      setDayTime("buenas tardes  👋🏼")
    }
    else{
      setDayTime("buenas noches  👋🏼")
    }
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsSidebarOpen(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  const handleIframeLoad = useCallback(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'Archivo Fotografico':
        return (
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Archivo Fotografico</h2>
            <Calendar
              mode="single"
              selected={selectedDate}
              onDateSelect={setSelectedDate}
              className="w-full max-w-sm mx-auto rounded-md border"
            />
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold mb-2">Fotos del dia {selectedDate.toDateString()}</h3>
              <ImageGallery selectedDate={selectedDate} user={userdata} />
            </div>
          </div>
        );
      case 'Inicio':
        return (
          <div className="h-full flex flex-col sm:px-40">
            <h2 className="text-xl font-semibold mb-4 mx-auto">Hola, {dayTime}!</h2>
            <h2 className="text-xl font-semibold mb-4">Inicio</h2>
            <div className="flex-grow relative w-screen overflow-hidden iframe-container">
              {/* {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-100 z-10">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                </div>
              )} */}
              <iframe
                src={livecam}
                title="Live Image"
                className="absolute items-center justify-center h-full rounded-lg iframe-content"
                onLoad={handleIframeLoad}
                allowFullScreen
              />
            </div>
          </div>
        );
      case 'Cuenta':
        return (
          <div className="p-4">
            <UserProfileCard userData={userdata} />
          </div>
        );
      case 'Timelapse':
        return (
          <div className="pt-24 flex">
          <Timelapse
            videoSrc={timelapse}
            //audioSrc="https://lisual.com/wp-content/uploads/2024/04/futuristic-timelapse-11951-3.mp3"
          />
          
          <div className="bottom-0 right-0 p-4 object-cover">
          <img
        src={descarga}
        alt="Static image" 
        className="w-auto max-h-96" // Adjust the size as needed
      />
    </div>
    <img
        src={idea}
        alt="Static image" 
        className="fixed bottom-0 right-0 w-auto max-h-80" // Adjust the size as needed
      />
        </div>

        );
      case 'Whatsapp + Correo':
        return (
          <div className='p-4'>
            <NotificationForm id={userdata}></NotificationForm>
          </div>
        )
      case 'Redes Sociales':
        return (
          <div className='p-4'>
            <Instagram></Instagram>
          </div>
        )
        case 'Ayuda':
        return (
          <div className='p-4'>
            <FAQTab></FAQTab>
          </div>
        )
        case 'Clima':
          return(
            <div className='flex flex-col items-center min-h-screen'>
              <h2 className="text-center text-3xl font-semibold mb-4">Clima actual</h2>
              <WeatherComponent></WeatherComponent>
            </div>
            
          )
      default:
        return (
          <div>
            <h2 className="text-xl font-semibold mb-4">{activeTab}</h2>
            <div className="bg-white rounded-lg shadow p-4">
              <img
                src="/api/placeholder/800/400"
                alt="Seccion en progreso!"
                className="w-full h-48 object-cover rounded"
              />
            </div>
          </div>
        );
    }
  };

  const menuItems = [
    { name: 'Inicio', icon: <Home size={24} /> },
    { name: 'Timelapse', icon: <Archive size={24}/>},
    { name: 'Archivo Fotografico', icon: <Camera size={24} /> },
    { name: 'Whatsapp + Correo', icon: <BookUser size={24} /> },
    { name: 'Redes Sociales', icon: <PresentationIcon size={24} /> },
    { name: 'Cuenta', icon: <Users size={24} /> },
    { name: 'Clima', icon: <CloudSunIcon size={24} /> },
    { name: 'Ayuda', icon: <HelpCircle size={24} /> }
  ];

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`
          fixed inset-y-0 left-0 z-30 w-64 bg-sky-100 text-black transition-all duration-300 ease-in-out overflow-auto
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
          md:relative md:translate-x-0 ${isSidebarOpen ? 'md:w-64' : 'md:w-0 md:opacity-0'}
        `}
      >
        <div className="p-4">
          <img src={logo} alt="Logo" className="h-25 w-full object-contain" />
        </div>
        <nav className="mt-2">
          <ul className="space-y-2 px-2 mb-4">
            {menuItems.map((item) => (
              <li
                key={item.name}
                className={`border-blue-600 bg-white border-2 p-2 rounded-3xl cursor-pointer ${activeTab === item.name ? 'bg-white border-4 border-blue-600' : 'hover:border-4'} `}
                onClick={() => {
                  setActiveTab(item.name);
                  if (window.innerWidth < 768) {
                    setIsSidebarOpen(false);
                  }
                }}
              >
                <div className="flex items-center">
                  {item.icon}
                  <span className="ml-3 text-lg">{item.name}</span>
                </div>
              </li>
            )
            )}
            <li className={`border-blue-600 bg-white border-2 p-4 rounded-3xl cursor-pointer hover:border-4`}
                onClick={() => {
                  navigate('/');
                  if (window.innerWidth < 768) {
                    setIsSidebarOpen(false);
                  }
                }}>
                  <div className="flex items-center">
                    <DoorOpen/>
                  <span className="ml-3 text-lg">Salir</span>
                </div>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
      <header className="bg-white shadow-sm p-2 flex items-center justify-between">
          <button onClick={toggleSidebar} className="text-gray-500">
            <Menu size={24} />
          </button>
          <div className="flex items-center">
            <div 
              className="flex items-center cursor-pointer"
              onClick={() => setActiveTab("Cuenta")}
            >
              <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center text-gray-600 font-bold">
                {userdata.user ? userdata.user[0].toUpperCase() : 'U'}
              </div>
            </div>
          </div>
        </header>
        {/* Dashboard content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6 transition-all duration-300 ease-in-out flex flex-col">
          {renderContent()}
        </main>
      </div>

      {/* Overlay to close sidebar on mobile when clicking outside */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20 md:hidden"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default ConstructionDashboard;