import React, { useState, useRef, useCallback } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Play, Pause, Volume2, VolumeX } from 'lucide-react';

const StoryEditor = () => {
  const [videoData] = useState([
    { duration: '0:20', src: 'https://lisual.com/wp-content/uploads/2024/06/Storytelling-Video.mp4' },
    { duration: '0:11', src: 'https://lisual.com/wp-content/uploads/2024/06/Autoedicion-Lisual_1-2.mp4' },
    { duration: '0:38', src: 'https://lisual.com/wp-content/uploads/2024/06/5-Facts-Story.mp4' }
  ]);

  const [linkData] =useState(['//creatomate.com/forms/f4b28aa5-3fba-4aa2-8bc9-e277bd2890a8','https://creatomate.com/forms/66312313-9ea3-456c-af47-840028d1b857','https://creatomate.com/forms/41444766-4fd7-436c-a629-bc258bca76b5'])
  const [playStates, setPlayStates] = useState(videoData.map(() => false));
  const [mutedStates, setMutedStates] = useState(videoData.map(() => false));
  const [volumeLevels, setVolumeLevels] = useState(videoData.map(() => 1));
  const [showVolumeControls, setShowVolumeControls] = useState(videoData.map(() => false));
  const videoRefs = useRef(videoData.map(() => React.createRef()));

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handlePlayPause = (index) => {
    const video = videoRefs.current[index].current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
    setPlayStates(prev => {
      const newStates = [...prev];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const handleMute = (index) => {
    const video = videoRefs.current[index].current;
    video.muted = !video.muted;
    setMutedStates(prev => {
      const newStates = [...prev];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const handleVolumeChange = useCallback((index, value) => {
    const video = videoRefs.current[index].current;
    video.volume = value;
    setVolumeLevels(prev => {
      const newLevels = [...prev];
      newLevels[index] = value;
      return newLevels;
    });
    if (value > 0 && mutedStates[index]) {
      handleMute(index);
    }
  }, [mutedStates]);

  const handleEditClick = () => {
    toast.warning('Perdon! Esta funcion esta en progreso.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />
      <h1 className="text-xl font-bold mb-4">AUTOEDITA TUS STORY PARA DIFUNDIR EN REDES</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {videoData.map((video, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="relative aspect-[9/16]">
              <video
                ref={videoRefs.current[index]}
                className="absolute inset-0 w-full h-full object-cover"
                src={video.src}
                preload="metadata"
                onTimeUpdate={() => {
                  const video = videoRefs.current[index].current;
                  const progressBar = document.getElementById(`progress-${index}`);
                  if (progressBar) {
                    progressBar.value = (video.currentTime / video.duration) * 100;
                  }
                }}
              >
                Your browser does not support the video tag.
              </video>
              <div className="absolute inset-0 flex items-center justify-center">
                <button 
                  onClick={() => handlePlayPause(index)}
                  className="bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 transition-all"
                >
                  {playStates[index] ? <Pause size={24} color="black" /> : <Play size={24} color="black" />}
                </button>
              </div>
              <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2">
                <input
                  id={`progress-${index}`}
                  type="range"
                  className="w-full h-1 bg-white rounded-lg appearance-none cursor-pointer"
                  onChange={(e) => {
                    const video = videoRefs.current[index].current;
                    const time = (video.duration / 100) * e.target.value;
                    video.currentTime = time;
                  }}
                />
                <div className="flex items-center justify-between mt-1">
                  <div className="text-xs">
                    {formatTime(videoRefs.current[index].current?.currentTime || 0)} / {video.duration}
                  </div>
                  <div className="flex space-x-2 items-center">
                    <div className="relative">
                      <button 
                        onClick={() => handleMute(index)}
                        onMouseEnter={() => setShowVolumeControls(prev => {
                          const newStates = [...prev];
                          newStates[index] = true;
                          return newStates;
                        })}
                        className="p-1"
                      >
                        {mutedStates[index] ? <VolumeX size={20} /> : <Volume2 size={20} />}
                      </button>
                      {showVolumeControls[index] && (
                        <div 
                          className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-black bg-opacity-75 rounded p-2"
                          onMouseLeave={() => setShowVolumeControls(prev => {
                            const newStates = [...prev];
                            newStates[index] = false;
                            return newStates;
                          })}
                        >
                          <input
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={volumeLevels[index]}
                            onChange={(e) => handleVolumeChange(index, parseFloat(e.target.value))}
                            className="w-24 h-1 bg-white rounded-lg appearance-none cursor-pointer orient-vertical"
                          />
                        </div>
                      )}
                    </div>
                    <button className="p-1" onClick={() => videoRefs.current[index].current.requestFullscreen()}>
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 3H21V9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9 21H3V15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M21 3L14 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3 21L10 14" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex p-4 items-center text-center">
              <a
                className="w-full bg-orange-400 text-white py-2 px-4 rounded hover:bg-orange-500 transition duration-300 rounded-lg cursor-pointer"
                href={linkData[index]}
              >
                Editar plantilla
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StoryEditor;