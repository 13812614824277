import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Cloud, Thermometer, Wind, Droplets, Sun, Moon, Sunrise, Sunset } from 'lucide-react';

const WeatherCard = styled.div`
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  width: 400px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 16px;
  text-align: center;
  color: #333;
`;

const WeatherInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Temperature = styled.div`
  font-size: 36px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const Condition = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

const WeatherComponent = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating API call
    setTimeout(() => {
      setWeatherData({
        temperature: 12.8,
        condition: 'Cloudy',
        windSpeed: 3,
        windDirection: 194,
        humidity: 75,
        feelsLike: 11,
        uvIndex: 2,
        sunrise: '06:45',
        sunset: '19:30'
      });
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return <div>Loading weather data...</div>;
  }

  return (
    <WeatherCard>
      <Title>Buenos Aires</Title>
      <WeatherInfo>
        <Temperature>
          <IconWrapper><Thermometer size={32} /></IconWrapper>
          {weatherData.temperature}°C
        </Temperature>
        <Condition>
          <IconWrapper><Cloud size={32} /></IconWrapper>
          {weatherData.condition}
        </Condition>
      </WeatherInfo>
      <DetailRow>
        <DetailItem>
          <IconWrapper><Wind size={18} /></IconWrapper>
          Viento: {weatherData.windSpeed} km/h, {weatherData.windDirection}°
        </DetailItem>
        <DetailItem>
          <IconWrapper><Droplets size={18} /></IconWrapper>
          Humedad: {weatherData.humidity}%
        </DetailItem>
      </DetailRow>
      <DetailRow>
        <DetailItem>
          <IconWrapper><Thermometer size={18} /></IconWrapper>
          Sensacion Termica: {weatherData.feelsLike}°C
        </DetailItem>
        <DetailItem>
          <IconWrapper><Sun size={18} /></IconWrapper>
          Indice UV: {weatherData.uvIndex}
        </DetailItem>
      </DetailRow>
      <DetailRow>
        <DetailItem>
          <IconWrapper><Sunrise size={18} /></IconWrapper>
          Amanecer: {weatherData.sunrise}
        </DetailItem>
        <DetailItem>
          <IconWrapper><Sunset size={18} /></IconWrapper>
          Atardecer: {weatherData.sunset}
        </DetailItem>
      </DetailRow>
    </WeatherCard>
  );
};

export default WeatherComponent;    